import './App.css';
import React from 'react';

export default function About() {
  return (
    <div className="main_body_contain">
      <div className="result_container">
        <div className="result">
          <h3>What is RegExplain?</h3>
          <p className="about_description">
            Welcome to RegExplain, your comprehensive regex explainer. Powered by advanced AI
            technology, RegExplain goes beyond simple rule explanations to provide you with a
            holistic understanding and clear explanations of regular expressions (regex).
          </p>
          <h3>Demystify Regex with AI-Powered Explanations</h3>
          <p className="about_description">
            Are you tired of deciphering regex patterns on your own? Let RegExplain, your ultimate
            regex explainer powered by state-of-the-art AI, guide you. We explain regex in plain
            English, ensuring you grasp the intricacies and unlock the true potential of regular
            expressions. Our AI algorithms analyze regex patterns to provide step-by-step
            explanations, helping you navigate the world of regular expressions.
          </p>
        </div>
      </div>
    </div>
  );
}
