import './App.css';
import React from 'react';
import { FaTwitter } from 'react-icons/fa';

import { Link, BrowserRouter, Route, Switch } from 'react-router-dom';

import LandingPage from './LandingPage.jsx';
import About from './about.jsx';

function App() {
  return (
    <BrowserRouter className="header_container">
      <div className="header_bar">
        <h3 className="header_title">
          <Link to="/" className="header_title_link">
            RegExplain
          </Link>
        </h3>
        <nav>
          <ul>
            <li>
              <Link to="/about" className="navigation_button">
                About
              </Link>
            </li>
            <li>
              <a href="https://twitter.com/RegExplain">
                <FaTwitter color="lightgrey" fontSize="2em" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route path="/about" component={About} />
      </Switch>
    </BrowserRouter>
  );
}
export default App;
