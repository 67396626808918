import './App.css';
import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';

import { Spin } from 'antd';
import { Button, Space } from 'antd';
import { LikeOutlined, DislikeOutlined, LikeFilled, DislikeFilled } from '@ant-design/icons';

import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-7DCR56QG4H';
ReactGA.initialize(TRACKING_ID);

const DEFAULT_REGEX_EXPRESSIONS = [
  'd{5}-d{4}|d{5}',
  '(ddd) ddd-?dddd',
  '/^[a-z0-9_-]{3,16}$/',
  '/<\\/?[\\w\\s]*>|<.+[\\W]>/',
  '/\\[img\\](.*?)\\[\\/img\\]/',
  '/^(0?[1-9]|1[0-2]):[0-5][0-9]$/',
  '/^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/',
  '/^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,6})*$/',
  '/(?=(.*[0-9]))((?=.*[A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z]))^.{8,}$/',
  '/https?:\\/\\/(?:youtu\\.be\\/|(?:[a-z]{2,3}\\.)?youtube\\.com\\/watch(?:\\?|#\\!)v=)([\\w-]{11}).*/gi',
  '/https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_\\+.~#()?&//=]*)/'
];

export default function LandingPage() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState('');
  const [explanationId, setExplanationId] = useState(0);
  const [placeholder, setPlaceholder] = useState(''); // default/randomized regex
  const [userInputRegex, setUserInputRegex] = useState(''); // user input
  const [success, setSuccess] = useState(false);
  const [prevInputYLoading, setprevInputYLoading] = useState(); // y coord of text input with spinner
  const [prevInputY, setprevInputY] = useState(); // y coord of text input without spinner
  const [prevButtonsYLoading, setprevButtonsYLoading] = useState();
  const [prevButtonsY, setprevButtonsY] = useState();
  const [liked, setLiked] = useState();
  const [disliked, setDisliked] = useState();

  const getInputY = () => {
    let element = document.getElementById('txt_area');
    var rect = element.getBoundingClientRect();
    return rect.top;
  };

  const getButtonsY = () => {
    let element = document.getElementById('button_container');
    var rect = element.getBoundingClientRect();
    return rect.top;
  };

  useEffect(() => {
    setPlaceholder(selectRegexPlaceholder(''));
    setprevInputY(getInputY());
    setprevButtonsY(getButtonsY());
  }, []);

  useEffect(() => {
    OnResize();
  }, [placeholder, userInputRegex]);

  useEffect(() => {
    if (
      (!result && (!prevInputY || !prevButtonsY)) ||
      (result && (!prevInputYLoading || !prevButtonsYLoading))
    ) {
      return;
    }
    const inputYChange = result ? prevInputYLoading - getInputY() : prevInputY - getInputY();
    const buttonsYChange = result
      ? prevButtonsYLoading - getButtonsY()
      : prevButtonsY - getButtonsY();
    const fxInputMove = `transform: translate(0px, ${inputYChange}px);`;
    const fxButtonMove = `transform: translate(0px, ${buttonsYChange}px);`;
    const fxAnim = `transition: transform 0.5s; transform: translate(0, 0);`;
    const fxNoTrans = `transition: none;`;

    let input_txt = document.getElementById('txt_area');
    let buttons = document.getElementById('button_container');
    let expl = document.getElementById('explain');
    input_txt.style.cssText += fxInputMove;
    expl.style.cssText += fxInputMove;
    buttons.style.cssText += fxButtonMove;
    setTimeout(() => {
      input_txt.style.cssText += fxAnim;
      expl.style.cssText += fxAnim;
      buttons.style.cssText += fxAnim;
      setTimeout(() => {
        input_txt.style.cssText += fxNoTrans;
        expl.style.cssText += fxNoTrans;
        buttons.style.cssText += fxNoTrans;
        setprevInputY(getInputY());
        setprevButtonsY(getButtonsY());
      }, 500);
    }, 10);
  }, [result]);

  const resetInput = () => {
    setUserInputRegex('');
    setResult('');
  };

  const shuffleDefaultRegex = () => {
    setResult('');
    const newPlaceholder = selectRegexPlaceholder(placeholder);
    let input = document.getElementById('txt_area');
    input.classList.add('fade');
    setTimeout(() => {
      input.classList.remove('fade');
      setUserInputRegex('');
      setPlaceholder(newPlaceholder);
    }, 300);
  };

  const selectRegexPlaceholder = (curr) => {
    const num_default = DEFAULT_REGEX_EXPRESSIONS.length;
    let val = DEFAULT_REGEX_EXPRESSIONS[Math.floor(Math.random() * num_default)];
    while (val == curr) {
      val = DEFAULT_REGEX_EXPRESSIONS[Math.floor(Math.random() * num_default)];
    }
    return val;
  };

  const onSearch = () => {
    setprevInputY(getInputY());
    setprevButtonsY(getButtonsY());
    setLoading(true);
    setResult('');
    setSuccess(false);
    setLiked(false);
    setDisliked(false);

    const val = userInputRegex ? userInputRegex : placeholder;

    ReactGA.event('search');
    ReactGA.event({
      category: 'engagement',
      action: 'query_explanation',
      label: val
    });

    axios
      .post('https://api.regexplain.ai/api/v1/explain', { regex: val })
      .then((response) => {
        setResult(response.data.explanation);
        setExplanationId(response.data.explanation_id);
        setSuccess(true);
      })
      .catch((error) => {
        setResult(error.response.data.error);
        setExplanationId(0);
        setSuccess(false);
      })
      .finally(() => {
        setLoading(false);
        setprevInputYLoading(getInputY());
        setprevButtonsYLoading(getButtonsY());
      });
  };

  const vote_up = () => {
    const regex_input = userInputRegex ? userInputRegex : placeholder;

    if (success && !liked) {
      axios.post('https://api.regexplain.ai/api/v1/vote', {
        regex: regex_input,
        explanation_id: explanationId,
        vote: '+'
      });
    }

    setDisliked(false);
    setLiked(true);
  };

  const vote_down = () => {
    const regex_input = userInputRegex ? userInputRegex : placeholder;

    if (success && !disliked) {
      axios.post('https://api.regexplain.ai/api/v1/vote', {
        regex: regex_input,
        explanation_id: explanationId,
        vote: '-'
      });
    }

    setLiked(false);
    setDisliked(true);
  };

  function OnResize() {
    let txt = document.getElementById('txt_area');
    txt.style.height = 0;
    txt.style.height = txt.scrollHeight + 'px';
  }

  window.addEventListener('resize', OnResize);

  return (
    <div className="content">
      <div className="main_body_contain" id="main_body_contain">
        <h3 id="explain" className="explain">
          Explain RegEx in English
        </h3>
        <textarea
          id="txt_area"
          spellCheck={false}
          className="input_responsive"
          placeholder={placeholder}
          value={userInputRegex}
          onChange={(e) => {
            if (e.nativeEvent.inputType === 'insertLineBreak') {
              e.preventDefault();
            } else {
              setUserInputRegex(e.target.value);
            }
          }}
        />
        <div id="result_container" className="result_container">
          {result ? (
            <div>
              <p style={success ? {} : { color: 'red' }} id="result" className="result">
                {result}
              </p>
              <div style={{ textAlign: 'right' }}>
                <Space>
                  <Button
                    type="text"
                    icon={liked ? <LikeFilled /> : <LikeOutlined />}
                    onClick={vote_up}
                  />
                  <Button
                    type="text"
                    icon={disliked ? <DislikeFilled /> : <DislikeOutlined />}
                    onClick={vote_down}
                  />
                </Space>
              </div>
            </div>
          ) : (
            <div className="result"></div>
          )}
        </div>
        <div className="button_container" id="button_container">
          <button className="button1" onClick={shuffleDefaultRegex}>
            random
          </button>
          <button className="button1" onClick={resetInput}>
            reset
          </button>
          <button className="button1" onClick={onSearch}>
            explain
          </button>
        </div>
        {loading ? (
          <div className="spin_container" style={{ visibility: 'visible' }}>
            <Spin size="large" style={{ marginTop: 25, filter: 'grayscale(100%)' }} />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="instructions_container">
        <p className="instructions">
          <p className="instruct">
            <span className="instruct_title">[random]</span>: to see regex examples
          </p>
          <p className="instruct">
            <span className="instruct_title">[reset]</span>: to clear current search
          </p>
          <p className="instruct">
            <span className="instruct_title">[explain]</span>: to find out what your regex means!
          </p>
          <br></br>
        </p>
      </div>
    </div>
  );
}
